import {useEffect, useState} from 'react';

import apiService from '../ApiCallService'
import ErrorUtils from 'src/utils/ErrorUtils';
import StringUtils from 'src/utils/stringUtils';


/**
 * This class should only contain the API calls for notifications
 */
export default class NotificationService {
    /**
     * Get all notifications for a user
     *
     * @returns Notification[] A list of notifications for the user
     * @param query
     */
    getNotifications(query: string): [Notification[], boolean, string] {
        const [result, setResult] = useState<Notification[]>([]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getNotifications();
                    const json = await response.json();
                    setResult(json.notifications);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData()
            }
        }, []);
        return [result, loading, error];
    }

}
